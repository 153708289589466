﻿
/// <reference path="../@types/bootstrap-datetimepicker/index.d.ts" />
/// <reference path="../Components/saop-selectize-select.ts" />

import {  global } from "../Common/saop-common";
import { SaopSelectizeSelect } from "./saop-selectize-select";

export class SaopInputMask {

    init() {
        global.ConsoleLogDegug("SaopInputMask.init...");
        var inputDivs = document.querySelectorAll<HTMLElement>('input[data-inputmask-alias]');
        Inputmask().mask(inputDivs);

        $(".saop-decimal-2").inputmask({
            'alias': 'decimal',
            'radixPoint': ',',
            'digits': 2,
            'digitsOptional': false,
            'placeholder': '0,00'
        });

        $(".saop-decimal-3").inputmask({
            'alias': 'decimal',
            'radixPoint': ',',
            'digits': 3,
            'digitsOptional': false,
            'placeholder': '0,000'
        });  

        inputDivs.forEach(element => {
            global.ConsoleLogDegug("element.init......................" + element.id);

            let spanDiv:any;
            let elementAtrr = $(element).attr("data-inputmask-alias");
            if (global.isEmpty(elementAtrr)) {return;};
            global.ConsoleLogDegug(elementAtrr);
            spanDiv = $(element).parent();
            if (spanDiv === null) { return;};
            if (spanDiv.hasClass("has-float-label")===false) { return;};
            global.ConsoleLogDegug(spanDiv.get(0).id);

            let languageAtrr = (String)($("#siteLanguage").val());
            global.ConsoleLogDegug("language:"+languageAtrr);
            languageAtrr = global.isEmpty(languageAtrr) ? "sl" :languageAtrr;
            global.ConsoleLogDegug("language:"+languageAtrr);

            if (elementAtrr === "hh:mm:ss") {
                let iconBtn = $(spanDiv).find("#timeIcon");
                global.ConsoleLogDegug("clock...");

                global.ConsoleLogDegug("clock.blur.init:" + element.id);
                $(element).blur(function (event: any) {
                    global.ConsoleLogDegug("call.clock.blur:" + event.target.id);
                    var controlValue = event.target.value;
                    if (controlValue.includes("h")) {
                        event.target.value = "";
                        return;
                    }
                    controlValue = controlValue.replace("mm", "00");
                    controlValue = controlValue.replace("m", "0");
                    controlValue = controlValue.replace("ss", "00");
                    controlValue = controlValue.replace("s", "0");
                    event.target.value = controlValue;
                    // prozimo input even, ker smo vpisali novo vrednost 
                    const e = new Event("input");
                    event.target.dispatchEvent(e);
                });

                global.ConsoleLogDegug("clock.click.init:" + element.id);
                $(iconBtn).click(function (event: any) {
                    global.ConsoleLogDegug("clockIcon.clicked:" + element.id);
                    var ctrlInputCtrl = $(element).get(0) as HTMLInputElement;
                    var ctrlInputCtrlStrLength = ctrlInputCtrl.value.length * 2;
                    ctrlInputCtrl.focus();
                    ctrlInputCtrl.setSelectionRange(ctrlInputCtrlStrLength, ctrlInputCtrlStrLength);
                });
            } else if (elementAtrr === "dd.mm.yyyy" 
                // || elementAtrr === "dd.mm.yyyy hh:mm" 
                // || elementAtrr === "dd.mm.yyyy hh:mm:ss"
            ) {

                let iconBtn = $(spanDiv).find("#calendarIcon");

                global.ConsoleLogDegug("calendar...");

                let datepickerStartDate = new Date();
                if ($(element).attr("data-enable-select-pastdates") != undefined) {
                    if (($(element).attr("data-enable-select-pastdates")).toLowerCase() == 'true') {
                        datepickerStartDate = null;
                    }
                }

                if ($(element).attr("data-min-start-date") != undefined) {
                    var datepickerStartDate1 = $(element).attr("data-min-start-date");
                    datepickerStartDate = global.convertString2Date(datepickerStartDate1, "dd.mm.yyyy");
                }

                let datepickerEndDate = null;
                if ($(element).attr("data-max-end-date") != undefined) {
                    datepickerEndDate = $(element).attr("data-max-end-date");   
                    datepickerEndDate = global.convertString2Date(datepickerEndDate, "dd.mm.yyyy");
                }

                $(element).datepicker({
                    startDate: datepickerStartDate,
                    endDate: datepickerEndDate,
                    autoclose: true,
                    keyboardNavigation: false,
                    weekStart: 1,
                    format: "dd.mm.yyyy",
                    language: languageAtrr,
                    orientation: "bottom right",
                    showOnFocus:false
                    //forceParse:false,
                });   

                $(iconBtn).click(function() 
                {
                    global.ConsoleLogDegug("calendar...click");
                    var datepickerInputCtrl = $(element).get(0) as HTMLInputElement;
                    var datepickerInputCtrlStrLength = datepickerInputCtrl.value.length * 2;
                    //datepickerInputCtrl.focus();
                    $(element).datepicker("show");
                    datepickerInputCtrl.setSelectionRange(datepickerInputCtrlStrLength, datepickerInputCtrlStrLength)
                });  
                
            } else if (elementAtrr === "dd.mm.yyyy hh:mm") {
                global.ConsoleLogDegug("datetimepicker hh:mm ...");
                               
                let iconBtn = $(spanDiv).find("#calendarIcon");
                //
                $(element)
                .bind('keydown',function(e){

                    e.stopImmediatePropagation();
                })                               
                .datetimepicker({
                    format: 'DD.MM.YYYY H:mm',
                    locale: languageAtrr,
                    showTodayButton: true,
                });

                $(element).off("focus");

                $(iconBtn).click(function() 
                {
                    $(element).data("DateTimePicker").show();
                });                 
  
            } else if (elementAtrr === "dd.mm.yyyy hh:mm:ss") {
                global.ConsoleLogDegug("datetimepicker hh:mm:ss ...");
                               
                let iconBtn = $(spanDiv).find("#calendarIcon");
                //
                $(element)
                .bind('keydown',function(e){

                    e.stopImmediatePropagation();
                })                               
                .datetimepicker({
                    format: 'DD.MM.YYYY H:mm:ss',
                    locale: languageAtrr,
                    showTodayButton: true,
                });

                $(element).off("focus");
                //$(element).off("keydown");
                // .on('dp.hide', function(e){
                //     console.log('dp.hide');
                //   })   
                // .on('dp.show', function(e){
                //     console.log('dp.show');
                // });                
                //
                $(iconBtn).click(function() 
                {
                    // global.ConsoleLogDegug("calendar...click");
                    // if ($(document).find(".datepicker-dropdown").is(":visible")) {
                    $(element).data("DateTimePicker").show();
                });                 
            
                // $(element).focus(function (event: any) {
                //     $(element).data("DateTimePicker").hide();                 
                // });                
            } else {
                console.log("not implemented...");
            }

        });

        this.initMultiDateSelectDatepicker();

        this.initSelectizeSelect();

        this.initSelectizeMultiselectSelect();

        global.ConsoleLogDegug("initInputs...done");
    }

    private initMultiDateSelectDatepicker(): void {
        global.ConsoleLogDegug("SaopInputMask.initMultiDateSelectDatepicker...");

        var inputDivs = document.querySelectorAll<HTMLElement>('input[data-izbor-vec-dni]');

        inputDivs.forEach(element => {
            global.ConsoleLogDegug("element.init......................" + element.id);

            let spanDiv: any;
            spanDiv = $(element).parent();
            if (spanDiv === null) { return; };
            if (spanDiv.hasClass("has-float-label") === false) { return; };
            global.ConsoleLogDegug(spanDiv.get(0).id);

            let languageAtrr = (String)($("#siteLanguage").val());
            global.ConsoleLogDegug("language:" + languageAtrr);
            languageAtrr = global.isEmpty(languageAtrr) ? "sl" : languageAtrr;
            global.ConsoleLogDegug("language:" + languageAtrr);

            let iconBtn = $(spanDiv).find("#calendarIcon");

            global.ConsoleLogDegug("calendar...");

            let datepickerStartDate = new Date();
            if ($(element).attr("data-enable-select-pastdates") != undefined) {
                if (($(element).attr("data-enable-select-pastdates")).toLowerCase() == 'true') {
                    datepickerStartDate = null;
                }
            }

            if ($(element).attr("data-min-start-date") != undefined) {
                var datepickerStartDate1 = $(element).attr("data-min-start-date");
                datepickerStartDate = global.convertString2Date(datepickerStartDate1, "dd.mm.yyyy");
            }

            let datepickerEndDate = null;
            if ($(element).attr("data-max-end-date") != undefined) {
                datepickerEndDate = $(element).attr("data-max-end-date");
                datepickerEndDate = global.convertString2Date(datepickerEndDate, "dd.mm.yyyy");
            }

            $(element).datepicker({
                startDate: datepickerStartDate,
                endDate: datepickerEndDate,
                autoclose: false,
                multidate: true,
                keyboardNavigation: false,
                weekStart: 1,
                format: "dd.mm.yyyy",
                language: languageAtrr,
                orientation: "bottom right"
            });

            $(iconBtn).click(function () {
                global.ConsoleLogDegug("calendar...click");
                var datepickerInputCtrl = $(element).get(0) as HTMLInputElement;
                var datepickerInputCtrlStrLength = datepickerInputCtrl.value.length * 2;
                datepickerInputCtrl.focus();
                datepickerInputCtrl.setSelectionRange(datepickerInputCtrlStrLength, datepickerInputCtrlStrLength)
            });

        });

        global.ConsoleLogDegug("SaopInputMask.initMultiDateSelectDatepicker...done");
    }

    private initSelectizeSelect(): void {
        global.ConsoleLogDegug("SaopInputMask.initSelectizeSelect...");

        $('.selectizeSelect').each(function () {
            let saopSelectelement = new SaopSelectizeSelect();
            saopSelectelement.initControlByHtml(this);
        });

        global.ConsoleLogDegug("SaopInputMask.initSelectizeSelect...done");
    }

    private initSelectizeMultiselectSelect(): void {
        global.ConsoleLogDegug("SaopInputMask.initSelectizeMultiselectSelect...");

        $('.selectizeMultiselect').each(function () {
            let saopSelectelement = new SaopSelectizeSelect();
            saopSelectelement.initMultiselectControlByHtml(this);
        });

        global.ConsoleLogDegug("SaopInputMask.initSelectizeMultiselectSelect...done");
    }
}





