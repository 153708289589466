﻿import { global } from "../Common/saop-common";
import { SaopPopover } from "../Components/saop-popover";

export class MenuSidebar {

    protected _collElement: HTMLElement;
    protected _expElement: HTMLElement;
    private _menuSidebarPopover: SaopPopover;

    init(): void {
        global.ConsoleLogDegug("MenuSidebar.init...");

        this._collElement = document.getElementById("collapse-icon-coll");
        this._expElement = document.getElementById("collapse-icon-exp");
        if (this._collElement != null) this._collElement.style.display = "none";
        if (this._expElement != null) this._expElement.style.display = "block";

        let _this = this;

        // Expand icon click
        $("#collapse-icon-exp").off("click");
        $("#collapse-icon-exp").on('click', function () {
            global.ConsoleLogDegug("MenuSidebar.collapse-icon-exp.click");

            _this._menuSidebarPopover.enablePopover();
        });

        // Collapse icon click
        $("#collapse-icon-coll").off("click");
        $("#collapse-icon-coll").on('click', function () {
            global.ConsoleLogDegug("MenuSidebar.collapse-icon-coll.click");

            _this._menuSidebarPopover.disablePopover();
        });

        // Collapse click
        $('[data-toggle=sidebar-colapse]').click(
            function () {
                _this.sidebarCollapse();
            }
        );

        //menu item click
        $(".saop-bg-item").click(
            function () {
                //sessionStorage.setItem("saop-menu-previous-item-id", sessionStorage.getItem("saop-menu-item-id"));
                //sessionStorage.setItem("saop-menu-item-id", this.id);
                //sessionStorage.setItem("saop-menu-subitem-id", "0");
            }
        )

        //menu sub-item click
        //$(".saop-bg-subitem").click(
        //    function(){
        //        sessionStorage.setItem("saop-menu-subitem-id", this.id);
        //    }
        //)

        if (document.getElementById("menu-sidebar") != null)
            this.GetSessionStorageValues();
        else
            this.DeleteSessionStorageValues();

        this.initMenuSidebarPopover();
    }

    protected sidebarCollapse(): void {
        global.ConsoleLogDegug("MenuSidebar.sidebarCollapse");
        $('#menuTopIconLarge').toggleClass('d-none');
        $('#menuTopIconSmall').toggleClass('d-none');
        $('.sidebar-menu-caption').toggleClass('d-none');
        $('#sidebar-container').toggleClass('sidebar-expanded sidebar-collapsed');
        $('.saop-bg-subitems').toggleClass('d-none');
        $('#menuCloseButton').toggleClass('d-none');
        var SeparatorTitle = $('#sidebar-container');
        if (SeparatorTitle.hasClass('sidebar-collapsed')) {
            sessionStorage.setItem("saop-menu-sidebar-expanded", "true")
            this._collElement.style.display = "block";
            this._expElement.style.display = "none";
        } else {
            sessionStorage.setItem("saop-menu-sidebar-expanded", "false")
            this._collElement.style.display = "none";
            this._expElement.style.display = "block";
        }
    }

    protected menuItemCollapse(): void {
        global.ConsoleLogDegug("MenuSidebar.menuItemCollapse");
        //var previousItemID = sessionStorage.getItem("saop-menu-item-id");
        //var itemID = sessionStorage.getItem("saop-menu-item-id");
        //var subitemsID = itemID+"-collapse";
        //var subitemID = sessionStorage.getItem("saop-menu-subitem-id");

        //var previous = $("#" + previousItemID);
        //var item = $('#'+itemID);
        //var subitems = $("#" + subitemsID);
        //var subitem = $("#"+subitemsID+">#"+subitemID);

        //previous.removeClass("saop-bg-item-selected");//'un-color' previous item
        //previous[0].setAttribute("aria-expanded", "false");
        //item[0].setAttribute("aria-expanded", "true");
        //item.addClass("saop-bg-item-selected");//color item
        //subitems.addClass('show');//expand collapse
        //subitem.addClass("saop-bg-subitem-selected");//color subitem
    }

    protected GetSessionStorageValues(): void {
        global.ConsoleLogDegug("MenuSidebar.GetSessionStorageValues");
        //defaults
        //if (sessionStorage.getItem("saop-menu-item-id") == null) {
        //sessionStorage.setItem("saop-menu-sidebar-expanded", null);
        //    sessionStorage.setItem("saop-menu-previous-item-id", null);
        //    sessionStorage.setItem("saop-menu-item-id", "namizje");
        //    sessionStorage.setItem("saop-menu-subitem-id", "0");
        //}

        //sidebar expanded/contracted
        if (sessionStorage.getItem("saop-menu-sidebar-expanded") == "true") {
            this.sidebarCollapse();
        }

        //selected-menu item
        this.menuItemCollapse()
    }

    protected DeleteSessionStorageValues(): void {
        global.ConsoleLogDegug("MenuSidebar.DeleteSessionStorageValues");

        sessionStorage.removeItem("saop-menu-sidebar-expanded");
        //sessionStorage.removeItem("saop-menu-previous-item-id");
        //sessionStorage.removeItem("saop-menu-item-id");
        //sessionStorage.removeItem("saop-menu-subitem-id");
    }

    initMenuSidebarPopover() {
        global.ConsoleLogDegug("MenuSidebar.initMenuSidebarPopover");

        this._menuSidebarPopover = new SaopPopover(".menu-popup");
        this._menuSidebarPopover.initSelfContainerPopover();
        this._menuSidebarPopover.disablePopover();

        var elementVisible = $("#collapse-icon-coll").is(':visible');
        if (elementVisible) {
            this._menuSidebarPopover.enablePopover();
        } else {
            this._menuSidebarPopover.disablePopover();
        }

    }
}



