﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global } from "../Common/saop-common";
import { SaopServicesCore,SaopServicesCoreParams, SaopServicesCoreResponse, SaopServicesCoreResult } from './saop-services-core'
import { SaopMessageDlg, SaopMsgDlgButton } from '../Components/saop-message-dlg';
import { SaopTranslationService } from '../Core/saop-translate';
import {SaopFloatingLabel } from '../Components/saop-floating-label';

export class Error {
    public Code: string;
    public Message: string;
}

export enum SaopWfAction {
    wfaNone = 0
    ,wfaAddRecord = 1
    ,wfaEditRecord = 2
    ,wfaDeleteRecord = 3
    ,wfaRecordDestails = 4
}

export interface SaopWfSubmitResult {
    success:boolean;
    viewContent:string;
    redirectTo:string;
    alertMessage:string;
}

export class SaopViewForm {
    private _viewModalFrame:JQuery<HTMLElement>;
    private _viewModalBody:JQuery<HTMLElement>;
    private _view_title:JQuery<HTMLElement>;
    private _view_btnOk:JQuery<HTMLElement>;
    private _view_btnCancel:JQuery<HTMLElement>;
    private _servicesParams:SaopServicesCoreParams = {action:null,data:null,type:"POST"};
    private _terminology: string[];
    //
    public _libName:string;
    public saopServicesCore:SaopServicesCore = new SaopServicesCore();
    public translationService:SaopTranslationService;
    public contentElementId = ".saop-modal-body-element";
    public addRecordEndpoint: string = "";
    public editRecordEndpoint: string = "";
    public deleteRecordEndpoint: string = "";
    public manuallySubmitEndpoint: string = "";
    public saopFloatingLabel: SaopFloatingLabel;
    //
    public onSucccessCallback: string = "";
    public onErrorCallback: string = "";
    public onFailureCallback: string = "";
    //    
    public id:string;
    public title:string;
    public fullScreen:boolean = false;
    //
    public onClose: (params:any) => any = null;
    public onSubmit: (params:any) => any = null;
    public onError: (params:any) => any = null;

    constructor(viewId:string,libName:string = ""){
        global.ConsoleLogDegug("SaopViewForm...");
        global.ConsoleLogDegug("viewId:"+viewId);
        this.id = viewId;
        this._libName = libName;
        this.onSucccessCallback = libName+".onSubmitSuccess(data,"+libName+");";
        this.onErrorCallback = libName + ".onSubmitError(data," + libName + ");";
        this.onFailureCallback = libName + ".onSubmitFailure(xhr," + libName + ");";
        this.translationService = new SaopTranslationService();
        this.saopFloatingLabel = new SaopFloatingLabel();
        //  
    }

    public setTerminologyList(dictionary: string[] = null) {
        this._terminology = ["Uredi","Nov vnos","Podrobnosti","Brisanje"];
        if (dictionary != null) {
            this._terminology = this._terminology.concat(dictionary);
        }
        this.translationService.setTranslationsList(this._terminology);
    }

    public onBtnOkClick(sender:any):void{
        global.ConsoleLogDegug("onBtnOkClick...");
    }

    public onBtnCancel(sender:any):void{
        global.ConsoleLogDegug("onBtnCancel...");
    }

    public beforeViewShow(sender:any):void{
        global.ConsoleLogDegug(this.id+".beforeViewShow()");

        this._viewModalFrame = $(this.id);
        if (this._viewModalFrame == null) { console.log(this.id+" not found!");};
        //
        var contentSel = this.id + " " + this.contentElementId;
        this._viewModalBody = $(contentSel);
    }

    public afterViewShow(sender:any,wfAction:SaopWfAction):void{
        global.ConsoleLogDegug(this.id+".afterViewShow()");
        let _this = this;
        this._view_btnOk = $(""+this.id+" *> #btnOk");
        this._view_btnCancel = $(""+this.id+" *> #btnCancel");
        this._view_title = $(""+this.id+" *> #exampleModalLongTitle");
        //

        global.ConsoleLogDegug("afterViewShow title:"+ this.title);

        if (!global.isEmpty(this.title)){
            let _title = "";
            if (wfAction == SaopWfAction.wfaAddRecord){
                _title = this.translationService.translate("Nov vnos")+": "+this.title;
            } else if (wfAction == SaopWfAction.wfaEditRecord){
                _title = this.translationService.translate("Uredi")+": "+this.title;
            } else if (wfAction == SaopWfAction.wfaRecordDestails){
                _title = this.translationService.translate("Podrobnosti")+": "+this.title;
            }

            global.ConsoleLogDegug("afterViewShow _title:"+ _title);

            this._view_title.html(_title);
        } else {
            if (wfAction == SaopWfAction.wfaAddRecord){
                let defaultTitleAdd = $(""+this.id+" *> #addTitle").val().toString();
                this._view_title.html(defaultTitleAdd);
            } else {
                let defaultTitleEdit = $(""+this.id+" *> #editTitle").val().toString();
                this._view_title.html(defaultTitleEdit);
            }
        }

        this.viewShowComplete(sender, wfAction);

        //
        this.bindButtons(sender);

        this.bindViewEvents();

        this._viewModalFrame.modal('show');
        global.setModalFormZIndex(this.id);
        global.setFirstFormControlToFocus(this.id);
        //
        this.afterViewShowSuccess(sender);
    }

    public afterViewShowSuccess(sender:any):void{
        global.ConsoleLogDegug(this.id + ".afterViewShowSuccess()");
    }

    public viewShowComplete(sender: any, wfAction: SaopWfAction): void {
        global.ConsoleLogDegug(this.id + ".viewShowComplete()");
    }

    private bindButtons(sender: any): void {
        global.ConsoleLogDegug("bindButtons");
        let _this = this;

        this._view_btnOk = $("" + this.id + " *> #btnOk");
        this._view_btnOk.click(
            function () {
                _this.onBtnOkClick(sender);
            }
        );

        this._view_btnCancel = $("" + this.id + " *> #btnCancel");
        this._view_btnCancel.click(
            function () {
                _this.onBtnCancel(sender);
            }
        );
    }

    public bindViewEvents(): void {
        global.ConsoleLogDegug("bindViewEvents");

        let divForm = this._viewModalFrame.find("form");

        if (this.onSucccessCallback !== null) {
            divForm.attr("data-ajax-success", this.onSucccessCallback);
        }
        if (this.onErrorCallback !== null) {
            divForm.attr("data-ajax-error", this.onErrorCallback);
        }
        if (this.onFailureCallback !== null) {
            divForm.attr("data-ajax-failure", this.onFailureCallback);
        }
        
        this.saopFloatingLabel.init();        
    }

    private renderErrorsBanner(errors:Error[]):SaopServicesCoreResponse {
        let result:SaopServicesCoreResponse;
        result = {response:SaopServicesCoreResult.error,data:null}; 
        //
        let jsonData = JSON.stringify(errors);
        let _data = {jsonInput:jsonData};        
        //
        let _saopServicesCore = new SaopServicesCore();
        _saopServicesCore.afterSuccess = function (response:SaopServicesCoreResponse) {
            result = response;
        };
        _saopServicesCore.ExecuteAction({
            action: "Errors/RenderErrorsBanner"
            , type: "POST"
            , data: _data
            , async: false
        });

        return result;
    }

    public renderError(errMessage:string):void{
        try {
            global.ConsoleLogDegug("ajax.afterError");
            let _errors:Error[];
            _errors = [{Code:"",Message:errMessage}];
            
            let _parentView = $(this.id).parent();

            let _errorBannerId = this.id+"ErrorBanner";
            //let _view_banner = this._viewModalBody.find(_errorBannerId);
            let _view_banner = _parentView.find(_errorBannerId);
            if ( global.isUnDefined(_view_banner) || _view_banner.length == 0) {
                _view_banner = _parentView.append("<div id="+_errorBannerId+"></div>");
            }
            let _bannerResult = this.renderErrorsBanner(_errors);
            _view_banner.html(_bannerResult.data.viewContent);                
        }
        catch(err) {
            alert(err.message);
        }  
    }
    //public loadViewBody(wfAction:SaopWfAction,data:any,sender:any):SaopServicesCoreResult{
    public loadViewBody(wfAction:SaopWfAction,data:any,sender:any):void{
        global.ConsoleLogDegug("loadViewBody");
        let _this = this;
        this.beforeViewShow(sender);

        let _ajaxAction = wfAction == SaopWfAction.wfaAddRecord ? this.addRecordEndpoint : this.editRecordEndpoint;
        //_ajaxAction = getSiteAbsoluteURL(_ajaxAction);

        this._servicesParams = {action:_ajaxAction,data:data,type:"POST"};

        this.saopServicesCore.afterSuccess = function(_ajaxResult){
            if (_ajaxResult.response == SaopServicesCoreResult.success){
                global.ConsoleLogDegug("ajax.success");
                _this._viewModalBody.html(_ajaxResult.data);
                _this.afterViewShow(sender,wfAction);
            } else {
                global.ConsoleLogDegug("ajax.error");
                if ((global.isUnDefined(_ajaxResult.data) && global.isUnDefined(_ajaxResult.data.alertMessage)) == false){
                    _this.renderError(_ajaxResult.data.alertMessage);
                } else if (_ajaxResult.data.responseJSON && _ajaxResult.data.responseJSON.message){
                    alertify.error(_ajaxResult.data.responseJSON.message);
                }            
            }
            
        };

        this.saopServicesCore.afterError = function(_ajaxResult){
//###
            if ((global.isUnDefined(_ajaxResult.data) && global.isUnDefined(_ajaxResult.data.alertMessage)) == false){
                _this.renderError(_ajaxResult.data.alertMessage);
            } else {
                alert("Internal error!");
            }
        };

        this.saopServicesCore.ExecuteAction(this._servicesParams);
    }    

    public afterSubmitSuccess(result: SaopWfSubmitResult): void {
        global.ConsoleLogDegug("submit.afterExecuteSuccess");
    }

    public onSubmitSuccessComplete(result: SaopWfSubmitResult): void {
        global.ConsoleLogDegug("submit.onSubmitSuccessComplete");
    }

    public onSubmitError(result: SaopWfSubmitResult): void {
        global.ConsoleLogDegug("submit.onSubmitError");
    }

    public onSubmitFailure(result: any): void {
        global.ConsoleLogDegug("submit.onSubmitFailure");
        var saopServicesCore = new SaopServicesCore();
        saopServicesCore.handleSubmitError(result);
    }
 
    public onSubmitSuccess(result: SaopWfSubmitResult): void {
        global.ConsoleLogDegug("submit.onExecuteSuccess");
        if (result.success) {
            global.ConsoleLogDegug("submit.success");

            this._viewModalFrame.modal('hide');

            // just in case: odstrani, če je še vedno vidno, avtomatsko se ne zapre vedno :(
            $('.modal-backdrop').remove();
            $(document.body).removeClass("modal-open");

            if (result.alertMessage) {
                alertify.message(result.alertMessage);
            }  

            if (!global.isUnDefined(this.refreshData)){
                this.refreshData(result);
            }

            this.onSubmitSuccessComplete(result);
        } else {
            global.ConsoleLogDegug("submit.error");
            global.ConsoleLogDegug("reload view form");
            this.refreshContent(result.viewContent);
        }
        this.afterSubmitSuccess(result);
    }    

    public refreshContent(viewContent: string): void {
        this._viewModalBody.html(viewContent);
        this.bindButtons(null);
        this.bindViewEvents();

        this.onAfterRefreshContent();
    }

    public onAfterRefreshContent(): void {
        global.ConsoleLogDegug("onAfterRefreshContent");
    }

    public onDeleteRecord(deleteData:any):void {
        global.ConsoleLogDegug("onDeleteRecord");
        let _this = this;
        this._servicesParams = {action:this.deleteRecordEndpoint,data:deleteData,type:"POST"};

        this.saopServicesCore.afterSuccess = function(_ajaxResult){
            if (_ajaxResult.response == SaopServicesCoreResult.success){
                alertify.message(_ajaxResult.data.alertMessage);
                if (!global.isUnDefined(_this.refreshData)){
                    _this.refreshData(deleteData);
                }
            } else {
                alertify.error(_ajaxResult.data.alertMessage);
            }
        };
        this.saopServicesCore.ExecuteAction(this._servicesParams);

        // let _ajaxResult = this.saopServicesCore.ExecuteAction(this._servicesParams);
        // if (_ajaxResult.response == SaopServicesCoreResult.success){
        //     alertify.message(_ajaxResult.data.alertMessage);
        //     if (!global.isUnDefined(this.refreshData)){
        //         this.refreshData(deleteData);
        //     }
        // } else {
        //     alertify.error(_ajaxResult.data.alertMessage);
        // }
        // return _ajaxResult.response;
    }
    
    public refreshData(sender:any):void {
        global.ConsoleLogDegug("refreshData");
    }

    public deleteRecord(title:string,message:string,deleteData:any):void{
        global.ConsoleLogDegug("deleteRecord.begin");
        let saopMessageDlg = new SaopMessageDlg(deleteData)  
        saopMessageDlg.onYes = this.onDeleteRecord.bind(this);
        saopMessageDlg.Show(message,title,1,[SaopMsgDlgButton.mbYes,SaopMsgDlgButton.mbNo]);
        global.ConsoleLogDegug("deleteRecord.end");
    }

    public addRecord(data:any,sender:any = null):void {
        global.ConsoleLogDegug("addRecord");
        let _result = this.loadViewBody(SaopWfAction.wfaAddRecord,data,sender);
    }

    public editRecord(data:any,sender:any = null):void {
        global.ConsoleLogDegug("editRecord");
        this.loadViewBody(SaopWfAction.wfaEditRecord,data,sender);
    }

    public recordDetails(data:any,sender:any = null):void {
        global.ConsoleLogDegug("editRecord");
        this.loadViewBody(SaopWfAction.wfaRecordDestails,data,sender);
    }

    public manuallySubmit(submitData: any, spinnerDivId: string = null): void {
        global.ConsoleLogDegug("manuallySubmit");

        let _this = this;

        if (global.isEmpty(spinnerDivId)) {
            this._servicesParams = { action: this.manuallySubmitEndpoint, data: submitData, type: "POST" };
        }
        else {
            this._servicesParams = { action: this.manuallySubmitEndpoint, data: submitData, type: "POST", spinnerDivId: spinnerDivId };
        }

        this.saopServicesCore.afterSuccess = function (_ajaxResult) {
            _this.onSubmitSuccess(_ajaxResult.data);
        };
        this.saopServicesCore.ExecuteAction(this._servicesParams);
    }

    public showViewForm(wfAction:SaopWfAction,data:any,sender:any = null):void{
        global.ConsoleLogDegug("showViewForm");
        if (wfAction == SaopWfAction.wfaAddRecord) {
            this.addRecord(data,sender);
        } else if (wfAction == SaopWfAction.wfaEditRecord) {
            this.editRecord(data,sender);
        } else if (wfAction == SaopWfAction.wfaRecordDestails) {  
            this.recordDetails(data,sender);          
        }   
        this.SetToFullScreen();      
    }  

    public SetToFullScreen(){
        if (this.fullScreen ){
            this._viewModalFrame.addClass("saop-modal-fullscreen-form");
            let _dialogDiv = this._viewModalFrame.find(".modal-dialog");
            _dialogDiv.removeClass('modal-dialog-centered');
            _dialogDiv.addClass('modal-fullscreen');
            _dialogDiv.removeAttr('style');
    
            let _content = this._viewModalFrame.find(".modal-content");
            _content.removeAttr('class');
            _content.addClass('modal-content');
        }
    }

    public refreshElementData(htmlContent:string,elementId:string): void {
        global.ConsoleLogDegug("refreshElementData");
        let _document = $(htmlContent);
        let _cell = _document.find(elementId);
        $(elementId).replaceWith(_cell);
      }

    //  
}
