﻿/// <reference path="../@types/data-tables/types.d.ts" />
import { global } from "../Common/saop-common";
import { SaopTranslationService } from '../Core/saop-translate';
import { SaopMessageDlg, SaopMsgDlgButton } from "./saop-message-dlg";

export class SaopTreeview {
    private _viewId: string;
    private libName: string;  
    private _translationService: SaopTranslationService;

    constructor(viewId: string, libName: string) {
        this._viewId = viewId;
        this.libName = libName;

        this._translationService = new SaopTranslationService();
    }  

    init() {
        global.ConsoleLogDegug("SaopTreeview.init");

        // prevajanje
        this.setFormTerminologyList();

        this.initEventListners();
    }

    private setFormTerminologyList() {
        global.ConsoleLogDegug("SaopTreeview.setFormTerminologyList");

        let terminology = ["Ni podatkov za prikaz.", "Brisanje", "Ali ste prepričani, da želite izbrisati element?"];
        this._translationService.setTranslationsList(terminology);
    }

    private initEventListners() {
        global.ConsoleLogDegug("SaopTreeview.initEventListners");

        let _this = this;

        $(this._viewId).off("click", ".saop-treeview-expand-colapse"); 
        $(this._viewId).on("click", ".saop-treeview-expand-colapse", function (event) {
            global.ConsoleLogDegug("SaopTreeview.saop-treeview-expand-colapse.click");

            event.stopPropagation();
            _this.treeviewExpandColapse(this);
        });

        $(this._viewId).off("click", ".saop-treeview-item-delete");
        $(this._viewId).on("click", ".saop-treeview-item-delete", function (event) {
            global.ConsoleLogDegug("SaopTreeview.saop-treeview-item-delete.click");

            event.stopPropagation();
            _this.deleteTreeviewItem(this);
        });

        $(this._viewId).off("click", ".saop-treeview-item-addSubItem");
        $(this._viewId).on("click", ".saop-treeview-item-addSubItem", function (event) {
            global.ConsoleLogDegug("SaopTreeview.saop-treeview-item-addSubItem.click");

            event.stopPropagation();
            _this.addTreeviewSubItem(this);
        });

        $(this._viewId).off("click", ".saop-treeview-item-insertItem");
        $(this._viewId).on("click", ".saop-treeview-item-insertItem", function (event) {
            global.ConsoleLogDegug("SaopTreeview.saop-treeview-item-insertItem.click");

            event.stopPropagation();
            _this.insertItem(this);
        });

        $(this._viewId).off("click", ".saop-treeview-item-edit");
        $(this._viewId).on("click", ".saop-treeview-item-edit", function (event) {
            global.ConsoleLogDegug("SaopTreeview.saop-treeview-item-edit.click");

            event.stopPropagation();
            _this.editTreeviewItem(this);
        });

        $(this._viewId).off("click", ".saop-treeview-add-item-btn");
        $(this._viewId).on("click", ".saop-treeview-add-item-btn", function (event) {
            global.ConsoleLogDegug("SaopTreeview.saop-treeview-add-item-btn.click");

            event.stopPropagation();
            _this.addTreeviewItem(this);
        });
    }

    private treeviewExpandColapse(elem: any): void {
        global.ConsoleLogDegug("SaopTreeview.treeviewExpandColapse");

        let id = $(elem).attr("data-toogleelementsid");
        let collapseItems = $(elem).hasClass("saop-img-down");

        $(elem).toggleClass("saop-img-down");
        $(elem).toggleClass("saop-img-right");

        // show/hide
        $(this._viewId + " div.saop-treeview-item-container[data-parentid='" + id + "']").each(function (index: number, childElem: any) {
            if (collapseItems) {
                $(childElem).hide("fast");
            }
            else {
                $(childElem).show("fast");
            }
        });    
    }

    private deleteTreeviewItem(elem: HTMLElement): void {
        global.ConsoleLogDegug("SaopTreeview.deleteTreeviewItem");

        let _this = this;
        let deleteTitle = this._translationService.translate("Brisanje");
        let deleteMessage = this._translationService.translate("Ali ste prepričani, da želite izbrisati element?");

        let saopMessageDlg = new SaopMessageDlg(null);
        saopMessageDlg.onYes = function () {
            // poiscemo parent z klasom saop-treeview-item-container in ga odstranimo ter vse childreen
            let tempElement = elem;
            let parentid = 0;
            for (var i = 0; i < 10; i++) {
                if (tempElement.classList.contains("saop-treeview-item-container")) {
                    parentid = global.convertString2Number(tempElement.getAttribute("data-parentid"));
                    // odstranimo element
                    tempElement.innerHTML = ""; 
                    tempElement.remove();
                    break;
                }
                tempElement = tempElement.parentElement;
            }
            if (parentid != null && parentid > 0) {
                // ali je se kaj ostalih child elementov z istim parentId?
                let childreenCount = $(_this._viewId + ' div.saop-treeview-item-container[data-parentid="' + parentid + '"]').length;
                if (childreenCount == 0) {
                    // zbrisali zadnjega child element, skrijemo expand/collapse ikono parent elementa
                    let parentElement = $(_this._viewId + ' div.saop-treeview-item[data-id="' + parentid + '"]');
                    if (parentElement != null) {
                        let expandCollapseElem = $(parentElement).find(".saop-treeview-expand-colapse");
                        if (expandCollapseElem != null) {
                            expandCollapseElem.hide();
                        }
                    }
                }
            }
        };
        saopMessageDlg.Show(deleteMessage, deleteTitle, 1, [SaopMsgDlgButton.mbYes, SaopMsgDlgButton.mbNo]);
    }

    private addTreeviewSubItem(addSubItemButtonElement: HTMLElement): void {
        global.ConsoleLogDegug("SaopTreeview.addTreeviewSubItem");

        // prikazemo expand/collapse ikono:
        // poiscemo parent, ki ima klas saop-treeview-item-info,
        let parentElem = addSubItemButtonElement.parentElement;
        for (var i = 0; i < 10; i++) {
            if (parentElem.classList.contains("saop-treeview-item-info")) {
                // poiscemo njegov child, ki ima klas saop-treeview-expand-colapse
                let expandCollapseElem = $(parentElem).find(".saop-treeview-expand-colapse");
                if (expandCollapseElem != null) {
                    expandCollapseElem.show();
                }
                break;
            }
            parentElem = parentElem.parentElement;
        }

        // poisci max Id Treeviews in ga povecamo za 1
        let newSubItemId = this.getMaxTreeviewId() + 1;
        // parent Id
        let newSubItemParentId = global.convertString2Number($(addSubItemButtonElement).attr("data-parentid"));

        this.addTreeviewSubItemExternal(addSubItemButtonElement, newSubItemId, newSubItemParentId);
    }

    private getMaxTreeviewId(): number {
        global.ConsoleLogDegug("SaopTreeview.getMaxTreeviewId");

        let maxId = 0

        $(this._viewId + " .saop-treeview-item").each(function (index, htmlTreeviewItem) {
            let id = global.convertString2Number(htmlTreeviewItem.getAttribute("data-id"));
            if (id != null && id > maxId) {
                maxId = id;
            }
        });

        return maxId;
    }

    public addTreeviewSubItemExternal(addSubItemButtonElement: HTMLElement, newSubItemId: number, newSubItemParentId: number): void {
        global.ConsoleLogDegug("SaopTreeview.addTreeviewSubItemExternal");
    }

    // metodo uporablja client, vrne content, ki jo dodamo v treeview
    public addTreeviewSubItemContent(addSubItemButtonElement: HTMLElement, newSubItemId: number, newSubItemParentId: number, htmlContent: string): void {
        global.ConsoleLogDegug("SaopTreeview.addTreeviewSubItemContent");

        let elemParent = $(this._viewId + ' div.saop-treeview-item[data-id="' + newSubItemParentId + '"]');
        elemParent.append(htmlContent);
    }

    private addTreeviewItem(addItemButtonElement: HTMLElement): void {
        global.ConsoleLogDegug("SaopTreeview.addTreeviewItem");

        // poisci max Id Treeviews in ga povecamo za 1
        let newItemId = this.getMaxTreeviewId() + 1;

        this.addTreeviewItemExternal(addItemButtonElement, newItemId, null);
    }

    public addTreeviewItemExternal(addItemButtonElement: HTMLElement, newItemId: number, newItemParentId: number): void {
        global.ConsoleLogDegug("SaopTreeview.addTreeviewItemExternal");
    }

    // metodo uporablja client, vrne content, ki jo dodamo v treeview
    public addTreeviewItemContent(addItemButtonElement: HTMLElement, newItemId: number, newItemParentId: number, htmlContent: string): void {
        global.ConsoleLogDegug("SaopTreeview.addTreeviewItemContent");

        // odstranimo add item html, ker ga bo ze dodal htmlContent
        $(this._viewId + " .saop-treeview-add-item-container").each(function (index, htmlTreeviewAddItem) {
            htmlTreeviewAddItem.remove();
        });

        let elemParent = $(this._viewId);
        elemParent.append(htmlContent);
    }

    private editTreeviewItem(elem: HTMLElement): void {
        global.ConsoleLogDegug("SaopTreeview.editTreeviewItem");

        // odstranimo css klas za obrobo vsem elementom in jo dodamo izbranemu
        this.clearTreeviewItemsSelectedCss();
        $(elem).addClass("saop-treeview-item-selected");

        this.editTreeviewItemExternal(elem);
    }

    // za externo uporabo
    public editTreeviewItemExternal(elem: HTMLElement): void {
        global.ConsoleLogDegug("SaopTreeview.editTreeviewItemExternal");
    }

    public clearTreeviewItemsSelectedCss(): void {
        global.ConsoleLogDegug("SaopTreeview.clearTreeviewItemsSelectedCss");

        $(this._viewId + " .saop-treeview-item-info").removeClass("saop-treeview-item-selected");
    }

    // elem je isti kot ga ponuja editTreeviewItem oz. editTreeviewItemExternal
    public refreshTreeviewItemHeader(elem: HTMLElement, header: string): void {
        global.ConsoleLogDegug("SaopTreeview.refreshTreeviewItemHeader");

        $(elem).find(".saop-treeview-item-header").text(header);
    }

    private insertItem(insertItemButtonElement: HTMLElement): void {
        global.ConsoleLogDegug("SaopTreeview.insertItem");

        // poiscemo parent, ki ima klas saop-treeview-item-container,
        let parentFound = false
        let parentElem = insertItemButtonElement.parentElement;
        for (var i = 0; i < 20; i++) {
            if (parentElem.classList.contains("saop-treeview-item-container")) {
                parentFound = true;
                break;
            }
            parentElem = parentElem.parentElement;
        }

        if (parentFound) {
            // poisci max Id Treeviews in ga povecamo za 1
            let newItemId = this.getMaxTreeviewId() + 1;
            // parentId
            let newItemParentId = global.convertString2Number($(parentElem).attr("data-parentid"));

            this.insertTreeviewItemExternal(parentElem, newItemId, newItemParentId);
        }   
    }

    public insertTreeviewItemExternal(itemToAppendTo: HTMLElement, newItemId: number, newItemParentId: number): void {
        global.ConsoleLogDegug("SaopTreeview.insertTreeviewItemExternal");
    }

    public insertTreeviewItemContent(itemToAppendTo: HTMLElement, newItemId: number, newItemParentId: number, htmlContent: string): void {
        global.ConsoleLogDegug("SaopTreeview.insertTreeviewItemContent");

        $(itemToAppendTo).after(htmlContent);
    }
}






