﻿/// <reference path="../@types/data-tables/types.d.ts" />
import { global } from "../Common/saop-common";

class selectItem {
    value: string;
    text: string;
}

class EditableColumnData {
    tip: string;
    selectItems: selectItem[];
    numericMinValue: number;
    numericMaxValue: number;
}

export class SaopWebGridEdit {
    public libName: string;  

    private _EditableColumnData: EditableColumnData;
    private _tdElement: HTMLTableCellElement;

    constructor(libName: string) {
        this.libName = libName;
    }  

    showWebGridEditableColumnEditControl(tdElement: HTMLTableCellElement): void {
        global.ConsoleLogDegug("SaopWebGridEdit.showWebGridEditableColumnEditControl");

        this._tdElement = tdElement;
        let tdElementHtml = this._tdElement.innerHTML;

        if (tdElementHtml.toLowerCase().indexOf("saop-table-edit-container") >= 0) {
            // edit kontrola ze obstaja
            return;
        }

        this._EditableColumnData = this.getDataAttribute(this._tdElement);

        this.AddEditableColumnEditHtml(this._EditableColumnData.tip);
    }

    onWriteValueToTDElementComplete(tdElement: HTMLTableCellElement): void {
        global.ConsoleLogDegug("SaopWebGridEdit.onWriteValueToTDElementComplete");
    }

    private getDataAttribute(element: HTMLElement): EditableColumnData {
        global.ConsoleLogDegug("SaopWebGridEdit.getDataAttribute");

        let data = new EditableColumnData();

        data.tip = element.getAttribute("data-edit-type");
        data.selectItems = $.parseJSON(element.getAttribute("data-edit-select-items"));
        data.numericMinValue = parseInt(element.getAttribute("data-edit-number-min"));
        data.numericMaxValue = parseInt(element.getAttribute("data-edit-number-max"));

        return data;
    }

    private AddEditableColumnEditHtml(tip: string): void {
        global.ConsoleLogDegug("SaopWebGridEdit.AddEditableColumnEditHtml");

        let tdElementText = this._tdElement.innerText;

        let input = this.getInputElement(tip, tdElementText);

        let containerCellDiv = document.createElement('div');
        containerCellDiv.classList.add("saop-table-edit-container-cell");
        containerCellDiv.appendChild(input);

        let containerDiv = document.createElement('div');
        containerDiv.classList.add("saop-table-edit-container");
        containerDiv.appendChild(containerCellDiv);

        this._tdElement.innerHTML = containerDiv.outerHTML;

        this.setInputElementAfterInit(tip, tdElementText);
    }

    private getInputElement(tip: string, inputValue: string): HTMLElement {
        global.ConsoleLogDegug("SaopWebGridEdit.getInputElement");

        let input: HTMLElement;

        switch (tip) {
            case "text":
                input = this.generateTextHtml();
                break;
            case "textarea":
                input = this.generateTextareaHtml();
                break;
            case "select":
                input = this.generateSelectHtml(inputValue);
                break;
            case "number":
                input = this.generateNumberHtml();
                break;
            default:
                input = this.generateTextHtml();
        }

        return input;
    }

    private generateTextHtml(): HTMLElement {
        global.ConsoleLogDegug("SaopWebGridEdit.generateTextHtml");

        let input: HTMLInputElement;

        input = document.createElement('input');
        input.type = "text";
        input.classList.add("saop-table-edit-container-cell-input");

        return input;
    }

    private generateTextareaHtml(): HTMLElement {
        global.ConsoleLogDegug("SaopWebGridEdit.generateTextareaHtml");

        let input: HTMLTextAreaElement;

        input = document.createElement('textarea');
        input.classList.add("saop-table-edit-container-cell-input");
        input.classList.add("saop-table-edit-container-cell-input-textarea");

        return input;
    } 

    private generateSelectHtml(selectValue: string): HTMLElement {
        global.ConsoleLogDegug("SaopWebGridEdit.generateSelectHtml");

        let input: HTMLSelectElement;

        input = document.createElement("select");
        input.classList.add("saop-table-edit-container-cell-input");

        this._EditableColumnData.selectItems.forEach((item) => {
            input.options.add(new Option(item.text, item.value, selectValue === item.value));
        });

        return input;
    }

    private generateNumberHtml(): HTMLElement {
        global.ConsoleLogDegug("SaopWebGridEdit.generateNumberHtml");

        let input: HTMLInputElement;

        input = document.createElement('input');
        input.type = "number";
        if (this._EditableColumnData.numericMinValue) {
            input.min = this._EditableColumnData.numericMinValue.toString();
        }
        if (this._EditableColumnData.numericMaxValue) {
            input.max = this._EditableColumnData.numericMaxValue.toString();
        }
        input.classList.add("saop-table-edit-container-cell-input");

        return input;
    }

    private setInputElementAfterInit(tip: string, tdElementText: string): void {
        global.ConsoleLogDegug("SaopWebGridEdit.setInputElementAfterInit");

        if (tip === "text") {
            let tdElementInput = this._tdElement.getElementsByTagName("input")[0];
            tdElementInput.value = tdElementText;
            tdElementInput.focus();
            tdElementInput.onblur = this.writeInputValueToTDElement.bind(this);
        }
        else if (tip === "textarea") {
            let tdElementInput = this._tdElement.getElementsByTagName("textarea")[0];
            tdElementInput.value = tdElementText;
            tdElementInput.focus();
            tdElementInput.onblur = this.writeTextAreaValueToTDElement.bind(this);
        }
        else if (tip === "select") {
            let tdElementInput = this._tdElement.getElementsByTagName("select")[0];
            tdElementInput.focus();
            tdElementInput.onblur = this.writeSelectValueToTDElement.bind(this);
        }
        else if (tip === "number") {
            let tdElementInput = this._tdElement.getElementsByTagName("input")[0];
            tdElementInput.value = tdElementText;
            tdElementInput.focus();
            tdElementInput.onblur = this.writeNumberValueToTDElement.bind(this);
        }
        else {
            let tdElementInput = this._tdElement.getElementsByTagName("input")[0];
            tdElementInput.value = tdElementText;
            tdElementInput.focus();
            tdElementInput.onblur = this.writeInputValueToTDElement.bind(this);
        }
    }

    private writeValueToTDElement(element: HTMLElement, inputVal: string): void {
        global.ConsoleLogDegug("SaopWebGridEdit.writeValueToTDElement");

        let tempElement = element;

        // poiscemo parent element, ki ima class saop-table-edit-container. Njegov parent je TD element.
        for (var i = 0; i < 10; i++) {
            if (tempElement.classList.contains("saop-table-edit-container")) {
                let tdElement = tempElement.parentElement;
                tdElement.innerHTML = inputVal;

                this.onWriteValueToTDElementComplete(this._tdElement);
                break;
            }
            tempElement = tempElement.parentElement;
        }
    }

    private writeInputValueToTDElement(sender: any): void {
        global.ConsoleLogDegug("SaopWebGridEdit.writeInputValueToTDElement");

        let target: HTMLInputElement = sender.target;
        let inputVal = target.value;

        // poiscemo parent, ki je TD element in v innerhtml vpisemo text value
        let parent = target.parentElement;
        this.writeValueToTDElement(parent, inputVal);
    }

    private writeTextAreaValueToTDElement(sender: any): void {
        global.ConsoleLogDegug("SaopWebGridEdit.writeTextAreaValueToTDElement");

        let target: HTMLTextAreaElement = sender.target;
        let inputVal = target.value;

        // poiscemo parent, ki je TD element in v inner html vpisemo text value
        let parent = target.parentElement;
        this.writeValueToTDElement(parent, inputVal);
    }

    private writeSelectValueToTDElement(sender: any): void {
        global.ConsoleLogDegug("SaopWebGridEdit.writeSelectValueToTDElement");

        let target: HTMLSelectElement = sender.target;
        let inputVal = target.value;

        // poiscemo parent, ki je TD element in v inner html vpisemo text value
        let parent = target.parentElement;
        this.writeValueToTDElement(parent, inputVal);
    }

    private writeNumberValueToTDElement(sender: any): void {
        global.ConsoleLogDegug("SaopWebGridEdit.writeNumberValueToTDElement");

        let target: HTMLInputElement = sender.target;
        let inputVal = target.value;

        // poiscemo parent, ki je TD element in v inner html vpisemo text value
        let parent = target.parentElement;
        this.writeValueToTDElement(parent, inputVal);
    }
  //
}






