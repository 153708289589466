﻿import { SaopPinchZoom } from "../Components/saop-pinch-and-zoom";
import { global } from "../Common/saop-common";

export class SaopPdfPreview {
    private _pdfjsLib:any;
    private _canvas:any;
    private _viewer:any;
    private _pdf:any;
    private _scale = 1;
    //
    private _documentId:string;
    private _url:string;
    private _fileName:string;
    private _base64:string;
    private _type:string;
    private _saopPinchZoom:SaopPinchZoom;

    loadpdfjsLib(pdfjsLib:any):void{
        this._pdfjsLib = pdfjsLib;
        this._saopPinchZoom =  new SaopPinchZoom();
    }

    renderPage(pageNumber:any, canvas:any):void {
        let _this = this;
        this._pdf.getPage(pageNumber).then(function(page:any) {
        _this._viewer = page.getViewport({ scale: _this._scale });

            //   let _viewerOffset = $('.pdf-viewer-inner').offset();
            //   let _w = Math.max(document.documentElement.clientWidth - _viewerOffset.left, window.innerWidth - _viewerOffset.left -272 || 0)

            // let _w = $('#pdf-viewer').width();
            // canvas.height = _this._viewer.height;
            // canvas.width = _w - 10;
            let _w = (_this._viewer.width as string)+'px'
            $('.saop-col-view').css('min-width', _w);

            canvas.height = _this._viewer.height - 10;
            canvas.width = _this._viewer.width - 10;          

            page.render({canvasContext: canvas.getContext('2d'), viewport: _this._viewer}).promise.then(function() {
                console.log('Page rendering complete.');
                _this._saopPinchZoom.init("pdf-viewer");
            });
        });
    }    

    setDefaultScale(scale:number):void{
        this._saopPinchZoom.setDefaultScale(scale);
    }

    base64ToUint8Array(base64:string):any{
        var raw = atob(base64);
        var uint8Array = new Uint8Array(raw.length);
        for (var i = 0; i < raw.length; i++) {
            uint8Array[i] = raw.charCodeAt(i);
        }
        return uint8Array;
    }   

    loadingPdfTask(loadingTask:any):void{
        let _this = this;
        loadingTask.promise.then(function(pdf:any) {
            _this._pdf = pdf;
            _this._viewer = document.getElementById('pdf-viewer');
           
            for(let page = 1; page <= pdf.numPages; page++) {
                
 
              _this._canvas = document.createElement("canvas");    
              _this._canvas.className = 'pdf-page-canvas'; 
              
              let _row = document.createElement("div");
              _row.className = 'row m-0 p-0'; 

              let _col = document.createElement("div");
              _col.className = 'col m-0 p-0 saop-col-view'; 

              _col.appendChild(_this._canvas); 

              _row.appendChild(_col); 

              _this._viewer.appendChild(_row);
              _this.renderPage(page, _this._canvas);
              //
              //new SaopPinchZoom().init("pdf-viewer");
              
            }

            // _this._saopPinchZoom.init("pdf-viewer");
            // $('.pdf-page-canvas').attr("onload","saopPdfPreview.setDefaultScale(0.6);");
            // $('div[onload]').trigger('onload');
        });        
    }

    getFileNameByUrl(url:string):void {
        let _this = this;
        $.ajax({
            type: "HEAD",
            url: url,         
            success: function(message, text, response){
                _this._type = response.getResponseHeader('Content-Type');
                _this.documentRender();
            },
        });   
    }

    getFileContentByUrl(url:string):void {
        let _this = this;
        $.ajax({
            type: "GET",
            url: url,         
            success: function(message, text, response){
                let _container = $('<div class="pdf-view-container" style="max-height:100%;overflow-y: auto;"></div>');
                _container.html(response.responseText);
                _container.append(_container);
                $('#pdf-viewer').append(_container);
            },
        });   
    }

    private renderErrorDiv():void{
        //$('#pdf-viewer').html('<div>Ni dokumentov za prikaz</div>');
    }

    documentPreview():void {
        let _this = this;
        this.renderErrorDiv();
        this._documentId = $("#myModel_DocumentId").val() as string;
        this._url = $("#myModel_FileUrl").val() as string;
        this._fileName = $("#myModel_FileName").val() as string;
        this._base64 = $("#myModel_Base64String").val() as string;

        let _w = screen.width;

        if (this._url.length > 0 && this._fileName.length == 0) {
            this.getFileNameByUrl(this._url);
        }

        if (this._base64.length == 0) {return;}

        let _fileExtension = this._fileName.split('.').pop().toLocaleLowerCase();

        if (_fileExtension.length == 0) {return;}

        this._type = "image/png";
        if (_fileExtension.toLocaleLowerCase() == 'pdf'){
            this._type = "application/pdf";
        } else {
            this._type = "image/"+_fileExtension;
        }

        this.documentRender();

    }    

    documentRender():void {
       let _w = screen.width;

        if(_w <= 800 && this._type.includes("pdf") == true){
            //
            this.preparePdfNodes();
            let loadingTask:any;
            if (this._base64.length > 0) {
                let _array = this.base64ToUint8Array(this._base64);
                loadingTask = this._pdfjsLib.getDocument(_array);   
            } else {
                loadingTask = this._pdfjsLib.getDocument(this._url); 
            }
            this.loadingPdfTask(loadingTask);
        } else if (this._type.includes("text/html")) {            
            this.getFileContentByUrl(this._url)
        } else {
            if (this._base64.length > 0) {
                $('#pdf-viewer').html('<embed Src="data:'+this._type+';base64,'+this._base64+'" class="mx-auto d-flex h-100 w-100" type="'+this._type+'"></embed>');
            } else {
                $('#pdf-viewer').html('<embed Src="'+this._url+'" class="mx-auto d-flex h-100 w-100" type="'+this._type+'""></embed>');        
            }          
        }
    }    

    private preparePdfNodes():void{
        let _parent = $('#pdf-viewer').parent();

        let _main = document.createElement("div");
        _main.className = 'pdf-viewer-main'; 

        let _inner = document.createElement("div");
        _inner.className = 'pdf-viewer-inner';

        let _viewer = document.createElement("div");
        _viewer.id = 'pdf-viewer';            

        _inner.appendChild(_viewer);

        _main.appendChild(_inner);

        _parent.html(_main);  
    }

    // loadBase64Pdf():void{
    //     let _base64 = $("#myModel_Base64String").val() as string;

    //     if (_base64.length > 0) {
    //         let _fileName = $("#myModel_FileName").val() as string;
    //         let _fileExtension = _fileName.split('.').pop().toLocaleLowerCase();
    //         let _w = screen.width;

    //         if(_w <= 800 && _fileExtension.toLocaleLowerCase() == "pdf"){
    //             //
    //             this.preparePdfNodes();
    //             let _array = this.base64ToUint8Array(_base64);
    //             var loadingTask = this._pdfjsLib.getDocument(_array);   
    //             this.loadingPdfTask(loadingTask);
    //         } else {
    //             let _type = "image/png";
    //             if (_fileExtension.toLocaleLowerCase() == 'pdf'){
    //                 _type = "application/pdf";
    //             } else {
    //                 _type = "image/"+_fileExtension;
    //             }

    //             $('#pdf-viewer').html('<embed Src="data:'+_type+';base64,'+_base64+'" class="mx-auto d-flex h-100 w-100" type="'+_type+'"></embed>');
    //         }
    //     }
    // }

    //
  

}





