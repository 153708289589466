﻿// knjiznica: https://selectize.dev/

/// <reference path="../@types/selectize-select/index.d.ts" />
import {  global } from "../Common/saop-common";
import { SelectOption } from "./saop-multiselect";

export class SaopSelectizeSelect {
    private libName: string;
    private _viewId: string;
    private _id: string;
    private _selectizeControl: HTMLElement;

    constructor(viewId: string = "", id: string = "", libName: string = "") {
        this.libName = libName;
        this._viewId = viewId;
        this._id = id;

        if (this._id != "") {
            this._selectizeControl = $(this._viewId + " " + this._id)[0];
        }
    }

    init(): void {
        global.ConsoleLogDegug("SaopSelectizeSelect.init");

        this.inicializeBindEvents();
    }

    initControlByJQuery(element: JQuery<HTMLElement>): void {
        this.initSelectizeSelect(element);
    }

    initControlByHtml(element: HTMLElement): void {
        this.initSelectizeSelect($(element));
    }

    initMultiselectControlByHtml(element: HTMLElement): void {
        this.initSelectizeMultiselect($(element));
    }

    getValue(): string {
        global.ConsoleLogDegug("SaopSelectizeSelect.getValue");

        return $(this._viewId + " " + this._id).attr("data-chosen");
    }

    getMultiselectValues(): string[] {
        global.ConsoleLogDegug("SaopSelectizeSelect.getMultiselectValues");

        let retValue: string[] = []; 

        $(this._viewId + " " + this._id + " option:selected").each(function (val) {
            retValue.push($(this).val().toString());
        });

        return retValue;
    }

    private initSelectizeSelect(element: JQuery<HTMLElement>): void {
        global.ConsoleLogDegug("SaopSelectizeSelect.initSelectizeSelect...");

        element.selectize({
            sortField: 'text',
            allowEmptyOption: true,
            render: {
                option: function (item: any, escape: any) {
                    var label = item.text;
                    var caption = item.value;
                    return "<div>" + '<span class="label" style="padding-left:13px">' + escape(label) + "</span>" + "</div>";
                },
            },
            onFocus: function () {
                if (this.$input.val() === "?") {
                    this.setValue("");
                }
            },
        });

        global.ConsoleLogDegug("SaopSelectizeSelect.initSelectizeSelect...done");
    }

    private initSelectizeMultiselect(element: JQuery<HTMLElement>): void {
        global.ConsoleLogDegug("SaopSelectizeSelect.initSelectizeMultiselect...");

        let _this = this;

        element.selectize({
            delimiter: "|",
            persist: false,
            maxItems: null,
            create: function (input: any) {
                return {
                    value: input,
                    text: input,
                };
            },
            onFocus: function () {
                let selValues = this.items;

                // ce je samo en element in ta "?", zbrisemo seznam, da se floating label umakne iz vvnosne kontrole
                if (selValues.length == 1 && selValues[0].trim() === "?") {
                    this.setValue("");
                }
            }           
        });

        global.ConsoleLogDegug("SaopSelectizeSelect.initSelectizeSelect...done");
    }

    private inicializeBindEvents(): void {
        global.ConsoleLogDegug("SaopSelectizeSelect.inicializeBindEvents");

        let _this = this; 

        $(this._viewId + " " + this._id).change(function () {
            var data = $(this).val().toString();
            _this.valueChanged(data);
        });
    }

    valueChanged(value: string): void {
        global.ConsoleLogDegug("SaopSelectizeSelect.valueChanged");
    }

    setValue(value: string): void {
        global.ConsoleLogDegug("SaopSelectizeSelect.setValue");
        this._selectizeControl.selectize.setValue(value);
    }

    setValues(values: string[]): void {
        global.ConsoleLogDegug("SaopSelectizeSelect.setValues");
        this._selectizeControl.selectize.setValue(values);
    }

    clearValue(): void {
        global.ConsoleLogDegug("SaopSelectizeSelect.clearValue");
        this._selectizeControl.selectize.clear();
    }

    refreshOptions(optionList: SelectOption[]): void {
        global.ConsoleLogDegug("SaopSelectizeSelect.refreshOptions");

        let _this = this;

        // zbrisemo izbrano opcijo, drugace ostane notri kot opcija
        this._selectizeControl.selectize.clear();

        this._selectizeControl.selectize.clearOptions();

        if (optionList.length > 0) {
            optionList.forEach(element =>
                _this._selectizeControl.selectize.addOption({ value: element.value, text: element.text })
            );
        }
    }

    disable(): void {
        global.ConsoleLogDegug("SaopSelectizeSelect.disable");
        this._selectizeControl.selectize.disable();
    }
}





