﻿/// <reference path="../@types/alertify/index.d.ts" />

export abstract class global {
    public static debugMode: boolean;
    public static siteBaseUrl: string;

    public static ConsoleLogDegug(message: any): void {
        if (this.debugMode) console.log(message);
    }

    public static GetClientLogModeStorageKeyValue(): string {
        return "SaopMojInfo.globalClientLogModeVal";
    }

    public static GetClientLogModeStorageValue(): boolean {
        let debugModeDefaultValue = false;

        var debugModeStringVal = this.ReadVariableFromStorage(global.GetClientLogModeStorageKeyValue());

        let debugModeVal = debugModeDefaultValue;
        if (debugModeStringVal) {
            debugModeVal = debugModeStringVal === "true" ? true : false;
        }

        return debugModeVal;
    }

    public static SetClientLogModeStorageValue(): void {
        let logMode = this.GetClientLogModeStorageValue();
        this.WriteVariableToStorage(global.GetClientLogModeStorageKeyValue(), !logMode);

        alertify.message("Client log mode enabled: " + !logMode);
    }

    public static getSiteBaseUrl(): string {
        return $("#siteBaseUrl").val() as string;
    }

    public static setHelpLink(): void {
        //nastavi help link, v odvisnosti od lokalizacije
        let _this = this;
        let svgHelp: any = document.getElementById('saop-help');

        if(svgHelp != null)
        {
            let language = svgHelp.getAttribute("data-language");

            //počakamo da se gneszdeni objekt naloži
            svgHelp.addEventListener("load", function () {
                let svgHelpLink = svgHelp.contentDocument.getElementById("saop-help-link");
                if (_this.isUnDefined(svgHelpLink) == false) {
                    if (language == "hr") {
                        svgHelpLink.setAttribute("href", "http://help.saop.hr/hr/mojinfo");
                    }
                    else if (language == "sr") {
                        svgHelpLink.setAttribute("href", "http://www.saop.rs/icenter/100/uputstva-za-upotrebu/");
                    }
                    else {
                        svgHelpLink.setAttribute("href", "https://si-icenter.knowledgeowl.com/help/mojinfo-navodila-za-uporabo-prenovljenega-portala");
                    }
                }
            });

            //zaradi braskalnik - mozilla firefox - izgleda, da je object ''loaded'' preden mu dodamo on loaded
            if($('#saop-help-link') != null)
            {
                const event = new Event('load');
                svgHelp.dispatchEvent(event);
            }
        }
    }

    public static formatDate(date: Date, format: string): string {
        const to2 = (n: number): string => ((Math.abs(n) < 10 ? '0' : '') + Math.abs(n));
        return format.replace(/[ydmh]+/gi, (match: string): string => {
            switch (match) {
                case 'yyyy': return date.getFullYear().toString();
                case 'd': return date.getDate().toString();
                case 'dd': return to2(date.getDate());
                case 'm': return (date.getMonth() + 1).toString();
                case 'mm': return to2(date.getMonth() + 1);
                case 'H': return date.getHours().toString();
                case 'HH': return to2(date.getHours());
                case 'MM': return to2(date.getMinutes());
                default:
                    return match;
            }
        });
    }

    public static convertString2Date(stringDate: string, format: string): Date {
        global.ConsoleLogDegug("convertString2Date");

        switch (format) {
            case "dd.mm.yyyy":
                let day = stringDate.substring(0, 2);
                let month = stringDate.substring(3, 5);
                let year = stringDate.substring(6, 10);

                return new Date(+year, +month - 1, +day);

            default:
                return null;
        }
    }

    public static convertString2Number(stringVal: string): number {
        global.ConsoleLogDegug("convertString2Number");

        let numberVal = parseInt(stringVal)

        if (isNaN(numberVal)) {
            return null;
        }

        return numberVal;
    }

    public static setModalFormZIndex(modalFormId: string): void {
        global.ConsoleLogDegug("setModalFormZIndex");
        global.ConsoleLogDegug("modalFormId:" + modalFormId);
        var zIndex = 1050 + (10 * $('.modal:visible').length);

        $(modalFormId).css('z-index', zIndex);
        setTimeout(function () {
            $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
        }, 0);
    }

    public static isEmpty(str: string) {
        return (!str || str.length === 0);
    }

    public static isUnDefined(_obj: any) {
        return (!_obj || _obj === null || typeof _obj === "undefined");
    }

    public static ReadVariableFromStorage(variableName: string): string {
        return localStorage.getItem(variableName);
    }

    public static WriteVariableToStorage(variableName: string, value: any): void {
        localStorage.setItem(variableName, value);
    }

    public static filterTableRows(filterVal: string, idTableElement: string): void {
        global.ConsoleLogDegug("filterTableRows");

        let val = $.trim(filterVal).replace(/ +/g, ' ').toLowerCase();
        let rows = $(idTableElement + " tr:has(td)");

        rows.show().filter(function () {
            var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
            return !~text.indexOf(val);
        }).hide();
    }

    public static setFirstFormControlToFocus(formId: string): void {
        global.ConsoleLogDegug("setFirstFormControlToFocus");

        // brez timeout funkcije focus ne dela :(
        setTimeout(function () {
            var firstFormControl = $(formId + " .form-control:visible:not(:disabled):first");
            if (firstFormControl) {
                firstFormControl.focus();
            }
        }, 500);
    }

    public static getRelativeSiteURL(controller: string): string {
        let _url = window.location.href;
        let iC = _url.indexOf(controller);
        if (iC > 0) {
            return _url.substring(0, iC) + controller;
        } else {
            return window.location.href;
        }
    }

    public static getSiteAbsoluteURL(relativePath: string): string {
        let controller = relativePath.split("/")[0];
        let method = relativePath.replace(controller, "").replace("//", "");

        // če relative path že vsebuje http, ga ne dodamo
        if (relativePath.indexOf("http") >= 0) {
            return relativePath;
        }

        return global.getSiteBaseUrl() + "/" + controller + method;
    }

    public static relodeCurrentPage(): void {
        window.location.reload();
    }

    public static getHtmlInputStringValue(parentId: string, elementId: string): string {
        let elementSelector = parentId === null ? elementId : parentId + " " + elementId;

        let element = $(elementSelector)
        return element.val().toString();
    }

    public static getHtmlInputNumericValue(parentId: string, elementId: string): number {
        return Number(this.getHtmlInputStringValue(parentId, elementId));
    }

    public static getHtmlInputBooleanValue(parentId: string, elementId: string): boolean {
        return this.getHtmlInputStringValue(parentId, elementId).toLowerCase() == "true" ? true : false;
    }

    public static getJQueryInputStringValue(element: JQuery<HTMLInputElement>): string {
        if (element.val() == null) {
            return null;
        }
        return element.val().toString();
    }

    public static getJQueryInputNumericValue(element: JQuery<HTMLInputElement>): number {
        let strVal = this.getJQueryInputStringValue(element);
        if (strVal == null || this.isEmpty(strVal)) {
            return null;
        }
        return Number(strVal);
    }

    public static getJQueryTextAreaStringValue(element: JQuery<HTMLTextAreaElement>): string {
        if (element.val() == null) {
            return null;
        }
        return element.val().toString();
    }

    public static getJQuerySelectValue(element: JQuery<HTMLSelectElement>): string {
        if (element.val() == null) {
            return null;
        }
        return element.val().toString();
    }

    public static isStringValueInStringValueList(stringValue: string, stringValueList: string[]): boolean {
        let retVal = false;

        stringValueList.forEach((item) => {
            if (item === stringValue) {
                retVal = true;
            }
        });

        return retVal;
    }

    public static getJQuerySelectText(element: JQuery<HTMLSelectElement>): string {
        if (element.val() == null) {
            return null;
        }
        return element.find("option:selected").text();
    }

    static copyToClipboard(copyText: string): void {
        navigator.clipboard.writeText(copyText);
    }
}

