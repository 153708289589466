﻿/// <reference path="../@types/bootstrap-multiselect/index.d.ts" />
import { SaopTranslationService } from '../Core/saop-translate';

import { global } from "../Common/saop-common";

export interface SaopMultiselectParams {
    target:string,
    selectAll?:boolean
}

export interface SaopMultiselectOptions {
    templatesButton?: string
}

export interface SelectOption {
    value: string;
    text: string;
}

export class SaopMultiselect {
    private _libName:string;
    private _translationService: SaopTranslationService;
    private _caption: string = "Skupina";
    private _multiSelectObject: any;
    private _options: SaopMultiselectOptions;
    //
    public msContainer:JQuery<HTMLElement>;
    public msButton: JQuery<HTMLButtonElement>;
    public msPopupContainer: JQuery<HTMLElement>;
    public msContainerLabel:JQuery<HTMLElement>;
    public selectedCB:any;

    constructor(libName:string = "", options:SaopMultiselectOptions = null) {
        global.ConsoleLogDegug("SaopMultiselect");
        this._translationService = new SaopTranslationService();
        this.setOptions(options);
    } 

    setOptions(options: SaopMultiselectOptions): void {
        // first set default options
        this._options = {
            templatesButton: '<button type="button" class="multiselect dropdown-toggle pr-0" data-toggle="dropdown"><span class="multiselect-selected-text"></span><span class="saop-img-down si-s float-right"></span></button>'
        }

        if (options != null) {
            this._options = options;
            if (options.templatesButton != null) {
                this._options.templatesButton = options.templatesButton;
            }
        }
    }

    setTerminologyList() {
        let _terminology: string[] = ['Išči', 'Vsi', 'Izberi prikazane', 'izbranih'];
        if (!global.isEmpty(this._caption)) {
            _terminology = ['Išči', 'Vsi', 'Izberi prikazane', 'izbranih', this._caption];
        } 
        this._translationService.setTranslationsList(_terminology);
    }

    bindDiv(elemet:any){
        this.msContainer = $(elemet.$container);
        this.msButton = $(elemet.$button);
        this.msContainerLabel = this.msContainer.find("#containerLabel");
        this.msPopupContainer = $(elemet.$popupContainer);
        //this.selectedCB = elemet.$select;

        if (this.selectedCB.val().length){
            this.setMsFloatingLabel(false);
        } else {
            this.setMsFloatingLabel(true);
        }        
    }

    setMsFloatingLabel(isEmpry:boolean){
        if (isEmpry){
            this.msContainerLabel.addClass('ms-float-label-empty');
            this.msContainerLabel.removeClass('ms-float-label-selected');
        } else {
            this.msContainerLabel.removeClass('ms-float-label-empty');
            this.msContainerLabel.addClass('ms-float-label-selected');            
        }        
    }

    onInitializedControl(element: any) {
        global.ConsoleLogDegug("SaopMultiselect.onInitializedControl");

        if (this.selectedCB.hasClass("input-validation-error")) {
            this.msButton.addClass("saop-error-input");
            this.msContainerLabel.addClass("saop-error-input-text");
        }

        // dodamo sticky css za prvi element
        this.addStickyClass();

        this.afterInitializedControl(element);
    }

    addStickyClass(): void {
        global.ConsoleLogDegug("SaopMultiselect.addStickyClass");

        let seznam = this.msContainer.find("button.multiselect-option.dropdown-item").each(function (index: number, elem: any) {
            let selectItem = $(elem);
            if (selectItem.text() === "") {
                selectItem.addClass("saop-multiselect-item-sticky");
            }
        });
    }

    init(target: string = "", caption = "" ){
        global.ConsoleLogDegug("SaopMultiselect.init");

        this._caption = caption;

        // poiscemo prevode (tudi za this._caption)
        this.setTerminologyList();

        if (global.isEmpty(target)) {target = ".saop-multiselect";}

        this.selectedCB = $(target);

        // ali ima selectedCB data-caption
        let selectCaption = "";
        if (!global.isEmpty(this._caption)) {
            selectCaption = this._translationService.translate(this._caption);
        }
        let dataCaption = this.selectedCB.attr("data-caption");
        if (!global.isEmpty(dataCaption)) {
            selectCaption = dataCaption;
        }

        let _this = this;

        $(target).multiselect({
            maxHeight: 350,  
            enableFiltering: true,
            includeSelectAllOption: true,
            enableCaseInsensitiveFiltering: true,
            enableFullValueFiltering: false,
            filterPlaceholder: this._translationService.translate("Išči"),
            nonSelectedText: '',
            allSelectedText:this._translationService.translate("Vsi"),
            selectAllText:this._translationService.translate("Izberi prikazane"),
            nSelectedText:this._translationService.translate("izbranih"),
            includeFilterClearBtn: true,
            disableIfEmpty: false,
            numberDisplayed: 2,
            buttonWidth: '200px',
            buttonClass :'saop-control form-control shadow-none ms-image-down pr-0',
            buttonContainer: '<div id="selected-parents-container"><label id ="containerLabel" class="ms-float-label ms-float-label-selected saop-txt-7" for="Name">'+selectCaption+'</label></div>',
            onSelectAll: function (selectedOptions: JQuery) {
                _this.bindDiv(this);
                _this.afterSelectAll(selectedOptions);
                _this.afterChangeCommonEvent(this.$select);
            },        
            onDeselectAll: function(selectedOptions:JQuery){
                _this.bindDiv(this);
                _this.afterDeselectAll(selectedOptions);
                _this.afterChangeCommonEvent(this.$select);
            },                  
            onChange: function(option: JQuery, checked: boolean){
                _this.bindDiv(this);
                _this.afterChange(option, checked);
                _this.afterChangeCommonEvent(this.$select);
            },
            onInitialized: function ($select, $container) {
                _this._multiSelectObject = this;
                _this.bindDiv(this);
                _this.onInitializedControl(this);
            },
            templates: {
                button: this._options.templatesButton,
                filter: '<li class="multiselect-item multiselect-filter"><div class="input-group mb-3"><input class="form-control multiselect-search" type="text" /><div class="input-group-prepend"><span class="input-group-text saop-img-search si-xs"></span></div></div></li>',
            }            
        })
        .multiselect('selectAll', false, true)
        .multiselect('updateButtonText');
        ;  
    }

    rebuild(): void {
        this.selectedCB.multiselect('rebuild');
    }

    afterSelectAll(selectedOptions: JQuery) {

    }

    afterDeselectAll(selectedOptions:JQuery){

    }

    afterChange(option: JQuery, checked: boolean){

    }

    afterChangeCommonEvent(element:any){

    }

    afterInitializedControl(element: any){
        global.ConsoleLogDegug("SaopMultiselect.afterInitializedControl");
    }

    // selectValues vrednosti
    setSelectValues(selectValues: string[]): void {
        // odznacimo vse zaposlene
        this.selectedCB.multiselect('deselectAll');

        if (selectValues != null) {
            // oznacimo izbrane
            this.selectedCB.multiselect('select', selectValues);
        }
        this.selectedCB.multiselect('refresh');

        if (this._multiSelectObject != null) {
            this.bindDiv(this._multiSelectObject);
            this.afterChangeCommonEvent(this._multiSelectObject.$select);
        } 
    }

    clearHtmlOptions(): void {
        this.selectedCB
            .find('option')
            .remove()
            .end();
    }

    refreshHtmlOptions(optionList: SelectOption[], selectAll: boolean = false): void {
        let _this = this;

        this.clearHtmlOptions();
        if (optionList.length > 0) {
            optionList.forEach(element => _this.selectedCB.append('<option value="' + element.value + '">' + element.text + '</option>'));
        }
        this.rebuild();
        this.addStickyClass();

        if (selectAll) {
            this.selectedCB.multiselect('selectAll');

            if (this.selectedCB.val().length) {
                this.setMsFloatingLabel(false);
            } else {
                this.setMsFloatingLabel(true);
            }
        }
    }
    
    //
}






