﻿/// <reference path="../@types/hammerjs/index.d.ts" />
import { global } from "../Common/saop-common";

export class SaopPinchZoom {
	private _initialized:boolean = false;
	// private _defaultSet = {x: -60, y: -53,z: 0.6};
	private _defaultSet = {x: 0, y: 0,z: 0.6};
	private _element :any;
	private _hammertime :any;

	private _fixHammerjsDeltaIssue :any;
	private _pinchStart :any;
	private _lastEvent :any;
	
	private _originalSize :any;	
	private _current :any;
	private _last :any;	
	private _pinchZoomOrigin :any;
	
	//
	
	sleep(milliseconds:number):void {
		const date = Date.now();
		let currentDate = null;
		do {
			currentDate = Date.now();
		} while (currentDate - date < milliseconds);
	}

	init(id:string){
		console.log("init...");
		this._element = document.getElementById(id);

		// let _w = $( window ).width();//this._element.offsetWidth;
		// //let _pdfW = parseInt($(".pdf-page-canvas").width())) * 0.6;
		// let _pdfW = $(".pdf-page-canvas").width() * 0.6;
		// this._defaultSet.x = ( _w - _pdfW) / 2;
		//

		this._hammertime = new Hammer(this._element, {});		
		
		this._hammertime.get('pinch').set({ enable: true });
		this._hammertime.get('pan').set({ threshold: 0 });

    	this._pinchStart = { x: 0, y: 0 };

		this._originalSize = {width: 200, height: 300};	
		this._current = {x: 0, y: 0, z: 0, zooming: false, width: this._originalSize.width * 1, height: this._originalSize.height * 1};	
		this._last = {x: this._current.x, y:this._current.y, z:this._current.z}		

		this.initEvents();

		this._initialized = true;
		
		this.setDefaultScale(this._defaultSet.z);
	}

	setDefaultScale(scale:number) {
		console.log("scale:"+scale.toString());
		if (this._initialized == false) {return; };
		//this.sleep(500);

		let _this = this;
		//
		this._defaultSet.z = scale;
		//
		this._current = {x: 0, y: 0, z: this._defaultSet.z, zooming: false, width: this._originalSize.width * 1, height: this._originalSize.height * 1};	
		this.update();
		//
		let _screenWidth = $( window ).width();
    	let _pdfWidth = parseInt($(".pdf-page-canvas").attr("width"));
		_pdfWidth = _pdfWidth * this._defaultSet.z;
		let _leftCanvas = (_screenWidth - (_screenWidth * this._defaultSet.z)) / 2;
		let _diffLeft = (_screenWidth - _pdfWidth) / 2;
		this._defaultSet.x = (_leftCanvas - _diffLeft + 5) * (-1);
		// //
		let _pdfDocHeight = $("#document-preview").height();
		if (_pdfDocHeight <= 0 ) {
			_pdfDocHeight = 280;
		}
		this._defaultSet.y = ((_pdfDocHeight - (_pdfDocHeight * this._defaultSet.z))/2) * (-1);		
		//
		
		this._current.x = this._defaultSet.x
		this._current.y = this._defaultSet.y;
		this._current.z = this._defaultSet.z;

		this._last.x = this._defaultSet.x
		this._last.y = this._defaultSet.y;
		this._last.z = this._defaultSet.z;

		this.update();
	}

	update():void {
	  this._current.height = this._originalSize.height * this._current.z;
	  this._current.width = this._originalSize.width * this._current.z;
	  this._element.style.transform = "translate3d(" + this._current.x + "px, " + this._current.y + "px, 0) scale(" + this._current.z + ")";
	}

	getCoords(elem:any):any { // crossbrowser version
	  var box = elem.getBoundingClientRect();

	  var body = document.body;
	  var docEl = document.documentElement;

	  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
	  var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

	  var clientTop = docEl.clientTop || body.clientTop || 0;
	  var clientLeft = docEl.clientLeft || body.clientLeft || 0;

	  var top  = box.top +  scrollTop - clientTop;
	  var left = box.left + scrollLeft - clientLeft;

	  return { x: Math.round(left), y: Math.round(top) };
	}


	getCoordinateShiftDueToScale(size:any, scale:any):any{
	  var newWidth = scale * size.width;
	  var newHeight = scale * size.height;
	  var dx = (newWidth - size.width) / 2;
	  var dy = (newHeight - size.height) / 2;
	  return {
		x: dx,
		y: dy
	  };
	}

	scaleFrom(zoomOrigin:any, currentScale:any, newScale:any):any {
	  var currentShift = this.getCoordinateShiftDueToScale(this._originalSize, currentScale);
	  var newShift = this.getCoordinateShiftDueToScale(this._originalSize, newScale);

	  var zoomDistance = newScale - currentScale
	  
	  var shift = {
		x: currentShift.x - newShift.x,
		y: currentShift.y - newShift.y,
	  }

	  var output = {
		x: zoomOrigin.x * shift.x,
		y: zoomOrigin.y * shift.y,
		z: zoomDistance
	  }
	  return output;
	}

	getRelativePosition(element:any, point:any, originalSize:any, scale:any):any {
	  var domCoords = this.getCoords(element);

	  var elementX = point.x - domCoords.x;
	  var elementY = point.y - domCoords.y;

	  var relativeX = elementX / (originalSize.width * scale / 2) - 1;
	  var relativeY = elementY / (originalSize.height * scale / 2) - 1;
	  return { x: relativeX, y: relativeY }
	}

	initEvents():void {
		let _this = this;

		this._hammertime.on('panend', (function(e:any) {
			console.log("panend");
		  if (this._current.scale != this._last.scale) {
		  	this._last.x = this._current.x;
		  }
		  this._last.y = this._current.y;
		  this._lastEvent = 'panend';
		}).bind(this));

		this._hammertime.on('pinchend', (function(e:any) {
			console.log("pinchend");
		  this._last.x = this._current.x;
		  this._last.y = this._current.y;
		  this._last.z = this._current.z;
		  this._lastEvent = 'pinchend';
		}).bind(this));	

		this._hammertime.on('pinchstart', (function(e:any) {
		  this._pinchStart.x = e.center.x;
		  this._pinchStart.y = e.center.y;
		  this._pinchZoomOrigin = this.getRelativePosition(this._element, { x: this._pinchStart.x, y: this._pinchStart.y }, this._originalSize, this._current.z);
		  this._lastEvent = 'pinchstart';
		}).bind(this));
		
		this._hammertime.on('pinch', (function(e:any) {
		  console.log("pinch");
		  var d = this.scaleFrom(this._pinchZoomOrigin, this._last.z, this._last.z * e.scale)
	      this._current.x = d.x + this._last.x + e.deltaX;
		  this._current.y = d.y + this._last.y + e.deltaY;
		  this._current.z = d.z + this._last.z;
		  this._lastEvent = 'pinch';
		  this.update();
		}).bind(this));
		
		this._hammertime.on('doubletap', (function(e:any) {
			console.log("doubletap");
			this.setDefaultScale(this._defaultSet.z);

		//   var _this = this; 
		//   var scaleFactor = 1;
		//   if (this._current.zooming === false) {
		// 	this._current.zooming = true;
		//   } else {
		// 	this._current.zooming = false;
		// 	scaleFactor = -scaleFactor;
		//   }

		//   this._element.style.transition = "0.3s";
		//   setTimeout(function() {
		// 	_this._element.style.transition = "none";
		//   }, 300)

		//   var zoomOrigin = this.getRelativePosition(this._element, { x: e.center.x, y: e.center.y }, this._originalSize, this._current.z);
		//   var d = this.scaleFrom(zoomOrigin, this._current.z, this._current.z + scaleFactor)
		//   this._current.x += d.x;
		//   this._current.y += d.y;
		//   this._current.z += d.z;

		//   this._last.x = this._current.x;
		//   this._last.y = this._current.y;
		//   this._last.z = this._current.z;

		//   this.update();
		}).bind(this));
		
		
		this._hammertime.on('pan', (function(e:any) {
		  console.log("pan");
		  if (this._lastEvent !== 'pan') {
			this._fixHammerjsDeltaIssue = {
			  x: e.deltaX,
			  y: e.deltaY
			};
		  };

		  if (this._current.z != this._defaultSet.z){
			this._current.x = this._last.x + e.deltaX - this._fixHammerjsDeltaIssue.x;
		  }
		  
		  this._current.y = this._last.y + e.deltaY - this._fixHammerjsDeltaIssue.y;
		  this._lastEvent = 'pan';
		  this.update();
		}).bind(this)); 
		
    //    $(window).resize(function () {
	// 		ProgressEvent;
    //        _this.setDefaultScale();
    //    }).resize();		

	}

	
}





