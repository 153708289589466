﻿/// <reference path="../@types/data-tables/types.d.ts" />
import { global } from "../Common/saop-common";
import { SaopWebGridFilter } from "../Components/saop-grid-filter";
import { DataTables } from "../@types/data-tables/types";
import { SaopTranslationService } from '../Core/saop-translate';
import { SaopWebGridEdit } from "../Components/saop-grid-edit";


// function statusFormatter(row: number, cell: number, value: any, columnDef: Slick.Column<Slick.SlickData>, dataContext: Slick.SlickData) {
//   if (value == null || value === "") {
//     return "-";
//   } else if (value < 50) {
//     return "<span style='color:red;font-weight:bold;'>" + value + "%</span>";
//   } else {
//     return "<span style='color:green'>" + value + "%</span>";
//   }
// }

export interface SaopWebGridToolbar {
  id?:string,
  caption:string,
  buttons?:SaopWebGridToolbarButton[]
  itemsTemplateId?: string;
} 

export interface SaopWebGridToolbarButton {
  id:string,
  caption :string,  
  class? :string,
  attributes?:string,
  onClickEvent?:string,
  template?: string;
} 

export interface SaopWebGridTogglePanel {
  enabled:boolean
} 

export interface SaopWebGridProperties {
  gridId:string
  ,css?:string
  ,togglePanel?:SaopWebGridTogglePanel
  ,toolbar?:SaopWebGridToolbar  
  ,dataTable?:SaopWebDataTable
}

export interface SaopWebDataTable {
  enabled:boolean
  ,columnDefsSettings?: DataTables.ColumnDefsSettings[]
  ,ordering?:boolean
  ,columns?:any[]
  ,data?: any
  ,order?: Array<(number | string)> | Array<Array<(number | string)>>
  ,editable?:boolean
}

export class SaopWebGrid {
  private _properties:SaopWebGridProperties
  private _tableDiv:HTMLTableElement;
  private _translationService: SaopTranslationService;
  private _saopWebGridEdit: SaopWebGridEdit;
  //
  public libName: string;  
  public webGridFilter:SaopWebGridFilter;
  public dataTable: DataTables.Api<any>;
  public markedColumnCheckbox: JQuery<HTMLInputElement>;

  constructor(libName: string) {
    this.libName = libName;
    this.webGridFilter = new SaopWebGridFilter();
    this._translationService = new SaopTranslationService();
    this._saopWebGridEdit = new SaopWebGridEdit("_saopWebGridEdit");    
    this._saopWebGridEdit.onWriteValueToTDElementComplete = this.onWriteValueToTDElementComplete.bind(this);
  }  

  setTerminologyList(){
    let _terminology: string[] = ['Ni podatkov za prikaz.'];
    this._translationService.setTranslationsList(_terminology);
  }

  //
  init(gridProp:SaopWebGridProperties) {
    this._properties = gridProp;
    this._tableDiv = document.querySelector(gridProp.gridId) as HTMLTableElement;
    //
    let _togglePanelDefault = {enabled:true};
    gridProp.togglePanel = {..._togglePanelDefault, ...gridProp.togglePanel}
    if (global.isUnDefined(gridProp.togglePanel)){
      gridProp.togglePanel = _togglePanelDefault;
    }      
    //

    this.webGridFilter.id = gridProp.gridId+"filter";
    this.generateHtmlTable();
    this.addGridToolbar();
    // det defaults
    let _Msg_emptyTable = this._translationService.translate("Ni podatkov za prikaz.")

    let _dataTableDef = {enabled:true,ordering:true};
    gridProp.dataTable = {..._dataTableDef, ...gridProp.dataTable}

    if (global.isUnDefined(gridProp.dataTable)){
      gridProp.dataTable = gridProp.dataTable;
    }    

    let _columnDefsSettingsDef = [{orderable: true, targets:  "no-sort"}];
    gridProp.dataTable.columnDefsSettings = {..._columnDefsSettingsDef,...gridProp.dataTable.columnDefsSettings}

    if (global.isUnDefined(gridProp.dataTable.columnDefsSettings)){
      gridProp.dataTable.columnDefsSettings = _columnDefsSettingsDef;
    }
    //
    if (!global.isUnDefined(this._properties.dataTable) 
      && this._properties.dataTable.enabled
      ){
      let _this = this;
      this.dataTable = 
        $(gridProp.gridId).DataTable(
          {
              "paging": false
              , "searching": true
              ,"autoWidth": true
              ,orderCellsTop: true
              //, "dom": "Rlfrtip"
              , initComplete: function () {
                if (gridProp.dataTable.ordering){
                  _this.webGridFilter.configSimpleFilter(this);                  
                  _this.webGridFilter.configSimpleSort(_this._properties.gridId);   
                }
                //if(!isUnDefined(_this._properties.dataTable.filters)) {
                //   _this.webGridFilter.configFilter(_this.libName,this, _this._properties.dataTable.filters);
                //}
              }
              ,order: _this._properties.dataTable.order
              // ,ajax: function (data, callback, settings) {
              //   callback({ data: data }) //reloads data 
              // } 
              ,columnDefs: _this._properties.dataTable.columnDefsSettings 
              ,ordering : gridProp.dataTable.ordering 
              ,language : {emptyTable:_Msg_emptyTable,zeroRecords:_Msg_emptyTable}
          }
        );    
      //
      $(gridProp.gridId+"_filter").remove();
      $(gridProp.gridId+"_info").remove(); 
      this.webGridFilter.setResizeEvent(this._properties.gridId);
      //
    }
   
    //
    if (this._properties.dataTable.editable) {
      this.initWebGridEditableColumnClickEvents();
    }
  }

  addToolbarButtons(gridPanel: JQuery<HTMLElement>,buttons:SaopWebGridToolbarButton[]){
    if(global.isUnDefined(buttons) == false){
      buttons.forEach(_button => {
        var buttonTemplate = 
        `
        <div class="col-auto my-auto" style="padding-left:21px;">
          <button class="btn saop-button-primary mx-auto d-block" id="${_button.id}" type="submit" onclick="${_button.onClickEvent}" style="padding-left:10px;"><span class="saop-img-plus"></span>${_button.caption}</button>
        </div>    
        `;

        if (_button.template != null) {
          buttonTemplate = _button.template;
        }

        gridPanel.find("#toolbarButtons").append(buttonTemplate);      
      });
    }
  }

  generateHtmlTable() {
    this.generateTableBody();        
    this.generateTableHead();
  }

  generateTableHead() {
    if (!global.isUnDefined(this._properties.dataTable)){
      if(!global.isUnDefined(this._properties.dataTable.columns)){
        let thead = this._tableDiv.createTHead();
        let row = thead.insertRow();
        for (let key of this._properties.dataTable.columns) {
          let th = document.createElement("th");
          let text = document.createTextNode(key.name);
          th.appendChild(text);
          row.appendChild(th);
        }
      }
    }
  }
  
  generateTableBody() {
    if (!global.isUnDefined(this._properties.dataTable)){
      if (!global.isUnDefined(this._properties.dataTable.data)){
        for (let element of this._properties.dataTable.data) {
          let row = this._tableDiv.insertRow();
          for (let key in element) {
            let cell = row.insertCell();
            let text = document.createTextNode(element[key]);
            cell.appendChild(text);
          }
        }
      }
    }
  }

  addGridToolbar() {
    let gridPanel: JQuery<HTMLElement>;
    let _css = global.isUnDefined(this._properties.css) == false ? this._properties.css :"";

    let gridPanelTemplate = `
        <div class="saop-table-fixhead ${_css}">
          <table id="#slickGrid#"></table>
        </div>
    `;
    if (!global.isUnDefined(this._properties.toolbar)) {
      if (global.isUnDefined(this._properties.toolbar.id)) {
        this._properties.toolbar.id = this._properties.gridId+"TB";
      }
      let gridTogglePanel = ``;
      let gridTogglePanelClass = "";
      let gridBorderBottom = "";
      if(this._properties.togglePanel.enabled) {
        gridTogglePanelClass = "collapse show";
        gridBorderBottom = "saop-border-bottom";

        gridTogglePanel = 
          `
          <div class="saop-form-panel-heading saop-toggle-panel">
            <p class="saop-txt-3 m-0" data-toggle="collapse" aria-expanded="true" data-target="#collapse${this._properties.toolbar.id}">
            ${this._properties.toolbar.caption} <span class="saop-img-down si-m saop-toggle-arrow right"></span>
            </p>
          </div>
          `;      
      }

      let _toolbarItems = ``;
      if (!global.isUnDefined(this._properties.toolbar.itemsTemplateId)){
        let _toolbarItemsTemplate = $(this._properties.toolbar.itemsTemplateId);
        if (_toolbarItemsTemplate.length > 0){
          _toolbarItems = _toolbarItemsTemplate.html();
          _toolbarItemsTemplate.remove();
        }
      }

      gridPanelTemplate = `
        <div id="${this._properties.toolbar.id}" class="panel panel-default ${gridBorderBottom}">
          ${gridTogglePanel}
          <div id="collapse${this._properties.toolbar.id}" class="saop-form-panel-body ${gridTogglePanelClass}">
            <div id="grid-toolbar" class="saop-table-toolbar">
              <div id="toolbarButtons" class="row mr-0 ml-0 justify-content-start" style="height:100%;">
              ${_toolbarItems}
              </div>
            </div>
            <div class="saop-table-fixhead ${_css}">
              <table id="#slickGrid#"></table>
            </div>
          </div>
        </div>
      `;
    }
    gridPanelTemplate = gridPanelTemplate.replace("#slickGrid#",this._properties.gridId.replace("#",""));
    gridPanel = $(gridPanelTemplate);
    if (!global.isUnDefined(this._properties.toolbar)) {
      this.addToolbarButtons(gridPanel,this._properties.toolbar.buttons);
    }

    let _tableHtml = this._tableDiv;

    $(this._properties.gridId).replaceWith(gridPanel);

    $(this._properties.gridId).replaceWith(_tableHtml);
  }

  refreshData(data:any) {
    let _this = this;
    this.dataTable.clear();
    data.each(function( index:any, tr:string ) {
      _this.dataTable.row.add($(tr));
    });
    this.dataTable.draw();    
  }

  replaceGridRows(htmlContent:string) {
    let _this = this;
    this.dataTable.clear();
    $(this._properties.gridId+" > tbody > tr", $(htmlContent)).each(
      function(index:any,tr:any) {
        _this.dataTable.row.add($(tr));
      }
    );
    this.dataTable.draw();    
  }  

  setTabelFoother():void {
    $(this._properties.gridId+' body')  
  }

  initWebGridColumnCheckboxBindClickEvent() {
    let _this = this;
    this.markedColumnCheckbox = null;

    if (global.isUnDefined(this._tableDiv)) {
      return;
    }

    $("#" + this._tableDiv.id + " .saop-td-input-checkbox").off("change");
    $("#" + this._tableDiv.id + " .saop-td-input-checkbox").on('change', function (row) {
      _this.markedColumnCheckbox = $("#" + _this._tableDiv.id + " #" + row.currentTarget.id);

      // odznacimo vse ostale checkboxe
      $("#" + _this._tableDiv.id + " input.saop-td-input-checkbox:not(#" + row.currentTarget.id + ")").each(
        function () {
          $("#" + _this._tableDiv.id + " #" + this.id).prop("checked", false);
        }
        );

      _this.afterColumnCheckboxChanged();
    });
  }

  afterColumnCheckboxChanged(): void {

  }

  initWebGridEditableColumnClickEvents(): void {
    let _this = this;

    $(this._properties.gridId).off("click", "tbody td[data-editable='true']");
    $(this._properties.gridId).on("click", "tbody td[data-editable='true']", function (e) {
      _this._saopWebGridEdit.showWebGridEditableColumnEditControl(this);
    });
  }
    
  private onWriteValueToTDElementComplete(tdElement: HTMLTableCellElement): void {
      this.onEditTdCellWriteValueToTDElementComplete(tdElement);
  }

  onEditTdCellWriteValueToTDElementComplete(tdElement: HTMLTableCellElement): void {
    // metoda za externo uporabo
  }
}






